import { Box, Loader, PageBlock } from 'braid-design-system';

export const PageLoader = () => (
  <PageBlock>
    <Box
      display="flex"
      justifyContent="center"
      paddingY={{ mobile: 'xxlarge', tablet: 'xxlarge', desktop: 'xxlarge' }}
      paddingX={{ mobile: 'large', tablet: 'none', desktop: 'none' }}
    >
      <Loader />
    </Box>
  </PageBlock>
);
