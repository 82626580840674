import IdTokenVerifier from 'idtoken-verifier';

import { config } from 'src/config';
import type { ClientContext } from 'src/types/types';

export const isExpired = (exp: number): boolean => {
  const currentDate = Number(String(new Date().valueOf()).substring(0, 10));
  return exp <= currentDate;
};

export const isTokenValid = (
  token: string,
  env: ClientContext['environment'],
): boolean => {
  const verifier = new IdTokenVerifier({
    issuer: config[env].apiEndpoint,
    audience: config[env].appUrl,
  });
  const { payload } = verifier.decode(token);

  if (
    payload === null ||
    typeof payload !== 'object' ||
    !payload.exp ||
    isExpired(payload.exp) ||
    !payload.hirerId ||
    !payload.email ||
    !payload.companyId
  ) {
    return false;
  }

  return true;
};
